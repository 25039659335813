<template>
  <nav v-if="prevPath || nextPath" :class="$style.root">
    <div :class="$style.column">
      <i18nLink v-if="prevPath && prevLabel" :class="$style.link" :to="prevPath" dir="prev">
        <span>←&nbsp;</span>
        <span :class="$style.label">
          {{ titleTruncated(prevLabel, 10) }}
        </span>
      </i18nLink>
    </div>

    <div :class="$style.column">
      <i18nLink v-if="nextPath && nextLabel" :class="$style.link" :to="nextPath" dir="next">
        <span :class="$style.label">
          {{ titleTruncated(nextLabel, 10) }}
        </span>
        <span>&nbsp;→</span>
      </i18nLink>
    </div>
  </nav>
</template>

<script lang="ts" setup>
const props = defineProps({
  prevPath: {
    type: [String, Object],
    required: false
  },
  nextPath: {
    type: [String, Object],
    required: false
  },
  prevLabel: {
    type: String,
    required: false
  },
  nextLabel: {
    type: String,
    required: false
  }
})

const titleTruncated = baseTruncateString
</script>

<style module>
.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--unit--horizontal);

  user-select: none;
}

.column {
  display: flex;
}

.column:first-child {
  justify-content: flex-start;
}

.column:last-child {
  justify-content: flex-end;
  text-align: right;
}

.label {
}

.link {
  composes: reset-link link from global;
  display: block;
}

.link:focus-within {
  text-decoration-line: none;
}
</style>
